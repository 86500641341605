/* eslint-disable no-restricted-properties */
import * as AC from "shared-lib/abstract-chart";
import type { AnyQuantity } from "shared-lib/uom";
import type { Quantity } from "uom-units";
import { UnitsFormat } from "uom-units";
import * as AI from "abstract-image";
import type { Unit } from "uom";
import { Amount, UnitFormat } from "uom";
import * as Style from "shared-lib/style";
// import * as Interpolation from "shared-lib/interpolation";
import * as R from "ramda";
import * as Texts from "shared-lib/language-texts";
import type { TranslateFunction } from "shared-lib/language-texts";
import type { LineTextAnchorPosition, LineTextPosition } from "shared-lib/abstract-chart";
// Uncomment below to use AMCA calculation
import * as Interpolation from "../fan-calculation-amca/interpolation";
import type { Curve, SpeedControl, FanAirResult, MotorResult } from "../result-items-types";

const numPoints = 100;

export interface FanChartsProps {
  readonly fan: FanAirResult;
  readonly motor: MotorResult | undefined;
  readonly flowUnit: Unit.Unit<Quantity.VolumeFlow>;
  readonly pressureUnit: Unit.Unit<Quantity.Pressure>;
  readonly powerUnit: Unit.Unit<Quantity.Power>;
  readonly translate: Texts.TranslateFunction;
  readonly showLineLabels: boolean;
  readonly style: Style.DiagramStyle;
  readonly powerCurves: ReadonlyArray<Curve> | undefined;
  readonly classCurves: ReadonlyArray<Curve> | undefined;
}

export interface FanCharts {
  readonly pressure: AC.Chart;
  readonly power: AC.Chart;
}

export function generateCharts({
  fan,
  flowUnit,
  pressureUnit,
  translate,
  powerUnit,
  showLineLabels,
  style,
  motor,
  powerCurves,
  classCurves,
}: FanChartsProps): FanCharts {
  const valid = fan.airFlow !== undefined && fan.desiredPointIsOutsideValidArea !== true;
  const props = {
    speedControl: fan.speedControl,
    showSystemLine: false,
    showTexts: true,
    desiredX: undefined,
    desiredY: undefined,
    minX: fan.minAirFlow,
    maxX: fan.maxAirFlow,
    valid: valid,
    workX: fan.airFlow,
    unitX: flowUnit,
    translate: translate,
    showLineLabels: showLineLabels,
    style: style,
    maxPower: motor?.power,
    powerCurves: powerCurves,
    classCurves: classCurves,
  };
  const pressure = generateChart({
    ...props,
    showSystemLine: true,
    desiredX: fan.desiredAirFlow,
    desiredY: fan.desiredExternalPressure,
    curves: fan.adjustedPressureCurves,
    // originalCurves: fan.pressureCurves,
    workY: fan.externalPressure,
    unitY: pressureUnit,
    style: style,
  });

  const power = generatePowerChart({
    ...props,
    showSystemLine: true,
    desiredX: fan.desiredAirFlow,
    desiredY: fan.desiredExternalPressure,
    curves: fan.powerCurves,
    // originalCurves: fan.pressureCurves,
    workY: fan.power,
    unitY: powerUnit,
    style: style,
  });
  return {
    pressure,
    power,
  };
}

interface FanChartProps {
  readonly speedControl: SpeedControl;
  readonly showTexts: boolean;
  readonly showSystemLine: boolean;
  readonly minX?: Amount.Amount<AnyQuantity>;
  readonly maxX?: Amount.Amount<AnyQuantity>;
  readonly desiredX?: Amount.Amount<AnyQuantity>;
  readonly desiredY?: Amount.Amount<AnyQuantity>;
  readonly valid: boolean;
  readonly workX?: Amount.Amount<AnyQuantity>;
  readonly workY?: Amount.Amount<AnyQuantity>;
  // readonly originalCurves?: ReadonlyArray<Curve>;
  readonly curves: ReadonlyArray<Curve>;
  readonly unitX: Unit.Unit<AnyQuantity>;
  readonly unitY: Unit.Unit<AnyQuantity>;
  readonly translate: Texts.TranslateFunction;
  readonly showLineLabels: boolean;
  readonly style: Style.DiagramStyle;
  readonly maxPower: Amount.Amount<Quantity.Power> | undefined;
  readonly powerCurves: ReadonlyArray<Curve> | undefined;
  readonly classCurves: ReadonlyArray<Curve> | undefined;
}

function generateChart(props: FanChartProps): AC.Chart {
  const {
    speedControl,
    showSystemLine,
    minX,
    maxX,
    desiredX,
    desiredY,
    valid,
    workX,
    workY,
    curves,
    unitX,
    unitY,
    translate,
    showLineLabels,
    style,
    powerCurves,
    classCurves,
  } = props;

  const xAxisUnitLabel = translate(Texts.unitLabel(unitX), UnitFormat.getUnitFormat(unitX, UnitsFormat)?.label);
  const xAxis =
    minX && maxX && AC.createLogarithmicAxis(Amount.valueAs(unitX, minX), Amount.valueAs(unitX, maxX), xAxisUnitLabel);
  const yAxis = createLogarithmicAxisY(curves, unitY, translate);

  const components = xAxis
    ? [
        ...generateCurves(
          style.actualLine,
          unitX,
          unitY,
          curves,
          showLineLabels,
          style.invalidColor,
          "bottomRight",
          "start"
        ),
        ...(powerCurves
          ? generateCurves(
              style.powerLine,
              unitX,
              unitY,
              powerCurves,
              showLineLabels,
              style.invalidAreaColor,
              "topLeft",
              "end"
            )
          : []),

        ...generateSystemLine(showSystemLine, yAxis, valid, desiredX, desiredY, unitX, unitY, style),
        ...generateArea(speedControl, Style.diagramBoxFanAreaColor, unitX, unitY, curves),
        ...generateClassAreas(speedControl, Style.diagramClassAreaColors, unitX, unitY, curves, classCurves ?? []),
        ...generateInvalidAreas(speedControl, style.invalidAreaColor, unitX, unitY, curves),
        ...generatePoint(
          false,
          valid ? style.workPointColor : style.invalidColor,
          false,
          desiredX,
          desiredY,
          unitX,
          unitY
        ),
        ...generatePoint(showLineLabels, style.workPointColor, true, workX, workY, unitX, unitY),
      ]
    : [];
  const chart = AC.createChart({
    components: components,
    xAxisBottom: xAxis,
    yAxisLeft: yAxis,
    backgroundColor: style.backgroundColor,
    gridColor: style.grid.lineColor,
    gridThickness: style.grid.lineThickness,
  });

  return chart;
}

function generateSystemLine(
  showSystemLine: boolean,
  yAxis: AC.Axis,
  valid: boolean,
  workX: Amount.Amount<AnyQuantity> | undefined,
  workY: Amount.Amount<AnyQuantity> | undefined,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  style: Style.DiagramStyle
): ReadonlyArray<AC.ChartComponent> {
  if (!workX || !workY || !showSystemLine) {
    return [];
  }

  const xWork = Amount.valueAs(unitX, workX);
  const yWork = Amount.valueAs(unitY, workY);
  const k = yWork / (xWork * xWork);
  const maxPoint = AI.createPoint(Math.sqrt(yAxis.max / k), yAxis.max);
  const minPoint = AI.createPoint(Math.sqrt(yAxis.min / k), yAxis.min);

  return [
    AC.createChartLine({
      points: [minPoint, maxPoint],
      thickness: style.systemLine.lineThickness,
      color: valid ? style.systemLine.lineColor : style.invalidColor,
    }),
  ];
}

function generatePoint(
  showLabels: boolean,
  color: AI.Color,
  filled: boolean,
  pX: Amount.Amount<AnyQuantity> | undefined,
  pY: Amount.Amount<AnyQuantity> | undefined,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>
): ReadonlyArray<AC.ChartComponent> {
  if (!pX || !pY) {
    return [];
  }
  const x = Amount.valueAs(unitX, pX);
  const y = Amount.valueAs(unitY, pY);
  const axisLines = showLabels ? "lines" : "none";
  if (filled) {
    return [
      AC.createChartPoint({
        shape: "circle",
        color: color,
        position: AI.createPoint(x, y),
        size: AI.createSize(8, 8),
        axisLines: axisLines,
      }),
    ];
  } else {
    return [
      AC.createChartPoint({
        shape: "circle",
        color: AI.transparent,
        stroke: color,
        strokeThickness: 2,
        position: AI.createPoint(x, y),
        size: AI.createSize(12, 12),
        axisLines: axisLines,
      }),
    ];
  }
}

function createLogarithmicAxisY(
  curves: ReadonlyArray<Curve>,
  unitY: Unit.Unit<AnyQuantity>,
  translate: Texts.TranslateFunction
): AC.LogarithmicAxis {
  const yPoints = [
    ...curves.map((c) => Amount.create(Interpolation.splineGetPoint(c.spline.xMin, c.spline) ?? 0, c.unitY)),
    ...curves.map((c) => Amount.create(Interpolation.splineGetPoint(c.spline.xMax, c.spline) ?? 0, c.unitY)),
  ].map((y) => Amount.valueAs(unitY, y));
  const lowestY = Math.min(...yPoints);
  const highestY = getSuitableMax(lowestY, yPoints, 0.1);
  const unitLabel = translate(Texts.unitLabel(unitY), UnitFormat.getUnitFormat(unitY, UnitsFormat)?.label);
  return AC.createLogarithmicAxis(lowestY, highestY, unitLabel);
}

function getSuitableMax(min: number, values: ReadonlyArray<number>, desiredPercentPadding: number): number {
  const valuesMax = Math.max(...values);
  const logValuesMax = Math.log10(valuesMax);
  const step = Math.pow(10, Math.floor(logValuesMax));
  const logMin = Math.log10(min);
  let steps = 1; //Math.ceil(valuesMax / step) * step;
  while (getPercentPadding(steps * step, logMin, logValuesMax) < desiredPercentPadding) {
    steps++;
  }
  return steps * step;
}

function getPercentPadding(max: number, logMin: number, logValuesMax: number): number {
  const logMax = Math.log10(max);
  const percentPadding = 1 - (logValuesMax - logMin) / (logMax - logMin);
  return percentPadding;
}

function generateCurves(
  style: Style.DiagramLineStyle,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curves: ReadonlyArray<Curve>,
  labels: boolean,
  invalidColor: AI.Color,
  textAnchorPosition: LineTextAnchorPosition,
  textPosition: LineTextPosition
): ReadonlyArray<AC.ChartComponent> {
  if (curves.length === 0) {
    return [];
  }

  return R.unnest<AC.ChartComponent>(
    curves.map((curve) =>
      generateCurve(
        unitX,
        unitY,
        curve,
        labels && curve.label ? curve.label : undefined,
        style,
        invalidColor,
        textAnchorPosition,
        textPosition
      )
    )
  );
}

function generateCurve(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curve: Curve,
  label: string | undefined,
  style: Style.DiagramLineStyle,
  invalidColor: AI.Color,
  textAnchorPosition: LineTextAnchorPosition,
  textPosition: LineTextPosition
): ReadonlyArray<AC.ChartComponent> {
  const workPoints = generateCurvePoints(unitX, unitY, curve, curve.workMin, curve.workMax);
  const curves = [
    AC.createChartLine({
      points: workPoints,
      color: style.lineColor,
      thickness: style.lineThickness,
      text: label,
      textAnchorPosition: textAnchorPosition,
      textPosition: textPosition,
      textBackground: true,
    }),
  ];
  if (curve.workMin > curve.spline.xMin) {
    const lowInvalidPoints = [
      ...generateCurvePoints(unitX, unitY, curve, curve.spline.xMin, curve.workMin),
      workPoints[0],
    ];
    curves.push(
      AC.createChartLine({
        points: lowInvalidPoints,
        color: invalidColor,
        thickness: style.lineThickness,
      })
    );
  }
  if (curve.workMax < curve.spline.xMax) {
    const highInvalidPoints = [
      workPoints[workPoints.length - 1],
      ...generateCurvePoints(unitX, unitY, curve, curve.workMax, curve.spline.xMax),
    ];
    curves.push(
      AC.createChartLine({
        points: highInvalidPoints,
        color: invalidColor,
        thickness: style.lineThickness,
      })
    );
  }
  return curves;
}

function generateArea(
  speedControl: SpeedControl,
  color: AI.Color,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curves: ReadonlyArray<Curve>
): ReadonlyArray<AC.ChartArea> {
  if (speedControl !== "Stepless" || curves.length === 0) {
    return [];
  }
  const bottomPoints = R.reverse(generateCurvePoints(unitX, unitY, curves[0]));
  const topPoints = generateCurvePoints(unitX, unitY, curves[curves.length - 1]);
  const rightPoints = R.reverse(
    curves.map((c) =>
      createPoint(unitX, unitY, c, c.spline.xMax, Interpolation.splineGetPoint(c.spline.xMax, c.spline) ?? 0)
    )
  );
  const leftPoints = curves.map((c) =>
    createPoint(unitX, unitY, c, c.spline.xMin, Interpolation.splineGetPoint(c.spline.xMin, c.spline) ?? 0)
  );
  const points = [...topPoints, ...rightPoints, ...bottomPoints, ...leftPoints];
  return [
    AC.createChartArea({
      points: points,
      color: color,
      fill: color,
    }),
  ];
}

function generateClassAreas(
  speedControl: SpeedControl,
  classColors: Style.ClassColors,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  pressureCurves: ReadonlyArray<Curve>,
  classCurves: ReadonlyArray<Curve>
): ReadonlyArray<AC.ChartArea> {
  if (speedControl !== "Stepless" || classCurves.length === 0 || pressureCurves.length === 0) {
    return [];
  }
  const areas: Array<AC.ChartArea> = [];
  for (let i = 0; i < classCurves.length; i++) {
    const classCurve = classCurves[i];
    const nextCurve = classCurves[i + 1] || pressureCurves[pressureCurves.length - 1];
    const bottomPoints = R.reverse(generateCurvePoints(unitX, unitY, classCurve));
    const topPoints = generateCurvePoints(unitX, unitY, nextCurve);
    const rightPoints = R.reverse(
      [classCurve, nextCurve].map((c) =>
        createPoint(unitX, unitY, c, c.spline.xMax, Interpolation.splineGetPoint(c.spline.xMax, c.spline) ?? 0)
      )
    );
    const leftPoints = [classCurve, nextCurve].map((c) =>
      createPoint(unitX, unitY, c, c.spline.xMin, Interpolation.splineGetPoint(c.spline.xMin, c.spline) ?? 0)
    );
    const points = [...topPoints, ...rightPoints, ...bottomPoints, ...leftPoints];
    const color = classColors[classCurve.id] || Style.diagramBoxFanAreaColor;
    areas.push(
      AC.createChartArea({
        points: points,
        color: color,
        fill: color,
      })
    );
  }
  return areas;
}

function generateCurvePoints(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curve: Curve,
  xMin: number = curve.spline.xMin,
  xMax: number = curve.spline.xMax
): ReadonlyArray<AI.Point> {
  return Interpolation.splineGetPoints(numPoints, curve.spline)
    .filter((p) => p.x >= xMin && p.x <= xMax)
    .map((p) => createPoint(unitX, unitY, curve, p.x, p.y));
}

function createPoint(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curve: Curve,
  x: number,
  y: number
): AI.Point {
  const chartX = Amount.valueAs(unitX, Amount.create(x, curve.unitX));
  const chartY = Amount.valueAs(unitY, Amount.create(y, curve.unitY));
  return AI.createPoint(chartX, chartY);
}

function generatePowerChart(props: FanChartProps): AC.Chart {
  const {
    minX,
    maxX,
    unitX,
    unitY,
    translate,
    curves,
    speedControl,
    workX,
    workY,
    maxPower,
    style,
    showLineLabels,
  } = props;
  const xAxisUnitLabel = translate(Texts.unitLabel(unitX), UnitFormat.getUnitFormat(unitX, UnitsFormat)?.label);
  const xAxis =
    minX && maxX && AC.createLogarithmicAxis(Amount.valueAs(unitX, minX), Amount.valueAs(unitX, maxX), xAxisUnitLabel);
  const yAxis = createLogarithmicAxisY(curves, unitY, translate);

  const components = xAxis
    ? [
        ...generateArea(speedControl, Style.diagramBoxFanAreaColor, unitX, unitY, curves),
        ...generatePowerBox(true, maxPower, unitY, yAxis, xAxis, maxPower, 50, showLineLabels, translate),
        ...generatePoint(showLineLabels, style.workPointColor, true, workX, workY, unitX, unitY),
      ]
    : [];
  const chart = AC.createChart({
    components: components,
    xAxisBottom: xAxis,
    yAxisLeft: yAxis,
    backgroundColor: Style.diagramBoxFanBackgroundColor,
    gridColor: Style.diagramGanymed.grid.lineColor,
  });

  return chart;
}
function generatePowerBox(
  insideWorkingArea: boolean,
  maxPowerDemand: Amount.Amount<Quantity.Power> | undefined,
  unitY: Unit.Unit<AnyQuantity>,
  yAxis: AC.LogarithmicAxis,
  xAxis: AC.LogarithmicAxis,
  power: Amount.Amount<Quantity.Power> | undefined,
  frequency: number | undefined,
  showLineLabels: boolean,
  translate: TranslateFunction
): ReadonlyArray<AC.ChartComponent> {
  if (!insideWorkingArea || !maxPowerDemand || !power) {
    return [];
  }
  const maxY = Amount.valueAs(unitY, maxPowerDemand);
  const diagramString = `${Amount.valueAs(unitY, power)} ${translate(
    Texts.unitLabel(unitY),
    UnitFormat.getUnitFormat(unitY, UnitsFormat)?.label
  )} 400V ${frequency}hz`;
  return [
    AC.createChartArea({
      points: [
        AI.createPoint(xAxis.min, yAxis.min),
        AI.createPoint(xAxis.min, maxY),
        AI.createPoint(xAxis.max, maxY),
        AI.createPoint(xAxis.max, yAxis.min),
      ],
      color: AI.transparent,
      fill: Style.diagramBoxFanPowerArea,
    }),
    AC.createChartLine({
      points: [AI.createPoint(xAxis.min, maxY), AI.createPoint(xAxis.max, maxY)],
      text: showLineLabels ? diagramString : undefined,
      textPosition: "start",
      textAnchorPosition: "bottomLeft",
      textBackground: true,
      color: Style.diagramActualRpmColor,
    }),
  ];
}

function generateInvalidAreas(
  speedControl: SpeedControl,
  color: AI.Color,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curves: ReadonlyArray<Curve>
): ReadonlyArray<AC.ChartArea> {
  if (speedControl !== "Stepless" || curves.length === 0) {
    return [];
  }
  const areas: Array<AC.ChartArea> = [];


  for(let i=0; i<curves.length -1;i++){
    const lowCurve = curves[i];
    const highCurve = curves[i+1];

    if (lowCurve.workMin > lowCurve.spline.xMin || highCurve.workMin > highCurve.spline.xMin) {
      const bottomPoints = R.reverse(generateCurvePoints(unitX, unitY, lowCurve, lowCurve.spline.xMin, lowCurve.workMin));
      const topPoints = generateCurvePoints(unitX, unitY, highCurve, highCurve.spline.xMin, highCurve.workMin);

      const right = curves.reduce<Array<AI.Point>>((sofar, c) => {
        const p = Interpolation.splineGetPoint(c.workMin, c.spline);
        if(p === undefined){
          return sofar;
        }
         sofar.push( createPoint(unitX, unitY, c, c.workMin, p));
         return sofar;

      }, []);

      const rightPoints = R.reverse(
        right
      );

      const leftPoints =  curves.reduce<Array<AI.Point>>((sofar, c) => {
        const p = Interpolation.splineGetPoint(c.spline.xMin, c.spline) ;
        if(p === undefined){
          return sofar;
        }
        sofar.push(  createPoint(unitX, unitY, c, c.spline.xMin, p));
        return sofar;
    }, []);



      const points = [...topPoints, ...rightPoints, ...bottomPoints, ...leftPoints];
      areas.push(
        AC.createChartArea({
          points: points,
          color: color,
          fill: color,
        })
      );
    }
    if (lowCurve.workMax < lowCurve.spline.xMax || highCurve.workMax > highCurve.spline.xMax) {
      const bottomPoints = R.reverse(generateCurvePoints(unitX, unitY, lowCurve, lowCurve.workMax, lowCurve.spline.xMax));
      const topPoints = generateCurvePoints(unitX, unitY, highCurve, highCurve.workMax, highCurve.spline.xMax);
      
      
      const leftPoints =  curves.reduce<Array<AI.Point>>((sofar, c) => {
        const p = Interpolation.splineGetPoint(c.workMax, c.spline) ;
        if(p === undefined){
          return sofar;
        }
        sofar.push(  createPoint(unitX, unitY, c, c.workMax, p));
        return sofar;
    }, []);




    const right = curves.reduce<Array<AI.Point>>((sofar, c) => {
      const p = Interpolation.splineGetPoint(c.spline.xMax, c.spline)
      if(p === undefined){
        return sofar;
      }
       sofar.push(   createPoint(unitX, unitY, c, c.spline.xMax, p));
       return sofar;

    }, []);

      const rightPoints = R.reverse(
       right
      );
      const points = [...topPoints, ...rightPoints, ...bottomPoints, ...leftPoints];
      console.log(points);
      areas.push(
        AC.createChartArea({
          points: points,
          color: color,
          fill: color,
        })
      );
    }

  }


  
  return areas;
}
